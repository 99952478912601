<template>
    <div>
        <ts-panel>
            <ts-panel-wrapper>
                <div class="tw-flex tw-justify-between">
                    <div class="tw-justify-start">
                        <h4 class="tw-mb-0">
                            {{ $t('system.overduePenalty') }}
                        </h4>
                    </div>
                    <div
                        class="tw-text-red-600"
                        v-if="errors.has('overduePenalty')"
                    >
                        {{ errors.first('overduePenalty') }}
                    </div>
                </div>
                <div
                    class="tw-mt-2 tw-p-2 tw-border tw-border-gray-200 tw-rounded-lg tw-h-72 tw-overflow-auto"
                >
                    <ts-table :records="resources">
                        <template v-slot:thead>
                            <thead>
                                <tr>
                                    <th class="tw-text-center">
                                        {{ $t('system.overdueType') }}
                                    </th>
                                    <th class="tw-text-center">
                                        {{ $t('system.overdueFrom') }}
                                    </th>
                                    <th class="tw-text-center">
                                        {{ $t('system.overdueTo') }}
                                    </th>
                                    <th class="tw-text-center">
                                        {{ $t('system.penaltyRate') }}
                                    </th>
                                    <th class="tw-text-center">
                                        <a href="#" class="text-primary">
                                            <i
                                                class="fa fa-plus"
                                                @click.prevent="addNewRow"
                                            ></i>
                                        </a>
                                    </th>
                                </tr>
                            </thead>
                        </template>
                        <template v-slot="{ record }">
                            <td width="13%" class="tw-text-center">
                                <Select
                                    v-model="record.overdue_type"
                                    :class="{
                                        'is-invalid': errors.has('overdue_type')
                                    }"
                                >
                                    <i-Option value="day">Day</i-Option>
                                    <i-Option value="amount">Amount</i-Option>
                                </Select>
                            </td>
                            <td width="20%" class="tw-text-center">
                                <InputNumber
                                    v-if="record.overdue_type == 'day'"
                                    style="width: 260px"
                                    v-model.number="record.overdue_from"
                                    :class="{
                                        'ivu-form-item-error':
                                            errors.has('overdue_from')
                                    }"
                                    :min="0"
                                ></InputNumber>
                                <Poptip
                                    trigger="focus"
                                    style="width: 100%"
                                    v-if="record.overdue_type == 'amount'"
                                >
                                    <div class="tw-w-full">
                                        <InputNumber
                                            style="width: 260px"
                                            v-model.number="record.overdue_from"
                                            :class="{
                                                'ivu-form-item-error':
                                                    errors.has('overdue_from')
                                            }"
                                            :min="0"
                                        ></InputNumber>
                                    </div>
                                    <div slot="content">
                                        {{
                                            record.overdue_from
                                                ? formatNumber(
                                                      record.overdue_from,
                                                      2
                                                  )
                                                : $t('loanRecovery.enterNumber')
                                        }}
                                    </div>
                                </Poptip>
                            </td>
                            <td width="20%" class="tw-text-center">
                                <InputNumber
                                    v-if="record.overdue_type == 'day'"
                                    style="width: 260px"
                                    v-model.number="record.overdue_to"
                                    :class="{
                                        'ivu-form-item-error':
                                            errors.has('overdue_to')
                                    }"
                                    :min="0"
                                ></InputNumber>
                                <Poptip
                                    trigger="focus"
                                    style="width: 100%"
                                    v-if="record.overdue_type == 'amount'"
                                >
                                    <div class="tw-w-full">
                                        <InputNumber
                                            style="width: 260px"
                                            v-model.number="record.overdue_to"
                                            :class="{
                                                'ivu-form-item-error':
                                                    errors.has('overdue_to')
                                            }"
                                            :min="0"
                                        ></InputNumber>
                                    </div>
                                    <div slot="content">
                                        {{
                                            record.overdue_to
                                                ? formatNumber(
                                                      record.overdue_to,
                                                      2
                                                  )
                                                : $t('loanRecovery.enterNumber')
                                        }}
                                    </div>
                                </Poptip>
                            </td>
                            <td width="20%" class="tw-text-center">
                                <div class="tw-flex">
                                    <InputNumber
                                        v-if="record.penalty_rate_type == '%'"
                                        style="width: 100%; text-align: right"
                                        :max="100"
                                        v-model.number="record.penalty_rate"
                                        :class="{
                                            'ivu-form-item-error':
                                                errors.has('penalty_rate')
                                        }"
                                        :min="0"
                                    ></InputNumber>
                                    <Poptip
                                        trigger="focus"
                                        style="width: 100%"
                                        v-if="record.penalty_rate_type == '$'"
                                    >
                                        <div class="tw-w-full">
                                            <InputNumber
                                                style="width: 100%"
                                                v-model.number="
                                                    record.penalty_rate
                                                "
                                                :class="{
                                                    'ivu-form-item-error':
                                                        errors.has(
                                                            'penalty_rate'
                                                        )
                                                }"
                                                class="tw-text-right"
                                                :min="0"
                                            ></InputNumber>
                                        </div>

                                        <div slot="content">
                                            {{
                                                record.penalty_rate
                                                    ? formatNumber(
                                                          record.penalty_rate,
                                                          2
                                                      )
                                                    : $t(
                                                          'loanRecovery.enterNumber'
                                                      )
                                            }}
                                        </div>
                                    </Poptip>
                                    <div>
                                        <Select
                                            v-model="record.penalty_rate_type"
                                            style="width: 70px"
                                            @on-change="record.penalty_rate = 0"
                                        >
                                            <i-Option value="%">%</i-Option>
                                            <i-Option value="$">$</i-Option>
                                        </Select>
                                    </div>
                                </div>
                            </td>
                            <td width="5%" class="tw-text-center">
                                <a
                                    href="#"
                                    class="text-danger"
                                    @click.prevent="removeRow(record)"
                                >
                                    <i class="fa fa-trash"></i>
                                </a>
                            </td>
                        </template>
                    </ts-table>
                </div>
            </ts-panel-wrapper>
        </ts-panel>
    </div>
</template>
<script>
import { Errors } from 'form-backend-validation'
import { clone } from 'lodash'
import { mapGetters } from 'vuex'

export default {
    name: 'overduePenalty',
    props: ['value', 'validate'],
    data () {
        return {
            errors: new Errors(),
            model: {},
            newRow: {
                overdue_type: 'amount',
                overdue_from: 0,
                overdue_to: 0,
                penalty_rate_type: '%',
                penalty_rate: 0
            }
        }
    },
    computed: {
        ...mapGetters(['formatCurrencyWithCode', 'formatNumber']),
        resources () {
            return this.model.overduePenalty
        }
    },
    created () {
        this.model = this.value
    },
    methods: {
        addNewRow () {
            this.model.overduePenalty.push(clone(this.newRow))
        },
        removeRow (item) {
            this.model.overduePenalty.splice(
                this.model.overduePenalty.indexOf(item),
                1
            )
        }
    },
    watch: {
        validate: function (error) {
            this.errors = new Errors(error.errors)
        }
    }
}
</script>
<style lang="css" scoped>
.table > thead > tr > th,
.table > tbody > tr > th,
.table > tfoot > tr > th,
.table > thead > tr > td,
.table > tbody > tr > td,
.table > tfoot > tr > td {
    /* border-color: #e2e7eb; */
    padding: 4px 2px !important;
    vertical-align: middle;
}
.table > :not(:first-child) {
    border-top: 1px solid grey;
}
.ivu-poptip .ivu-poptip-rel {
    display: none;
}
.ss {
    display: block;
}
.ivu-select-arrow .ivu-icon {
    display: none;
}
</style>
