<template>
    <div class="d-flex flex-column overflow-hidden h-100">
        <vue-custom-scrollbar
            class="app-content-padding flex-grow-1 overflow-hidden h-100"
        >
            <ts-page-title
                :title="$t('system.pageTitle')"
                :breadcrumb="[
                    { text: $t('home'), href: '/' },
                    {
                        text: $t('system.pageTitle'),
                        href: '/admin/systems'
                    },
                    {
                        text: $t('create'),
                        active: true
                    }
                ]"
            />
            <div class="demo-spin-article">
                <div class="row tw-mb-5">
                    <div class="col-md-5">
                        <general-setting v-model="model" :validate="errors" />
                    </div>
                    <div class="col-md-7">
                        <overdue-penalty
                            v-model="model"
                            :validate="errors"
                        />
                    </div>
                </div>
                <div class="row tw-mb-5">
                    <div class="col-md-12">
                        <repayment-item-priority
                            v-model="model"
                            :validate="errors"
                        />
                    </div>
                </div>
            </div>
            <Spin size="large" fix v-if="loading"> </Spin>
        </vue-custom-scrollbar>
        <div
            id="footer"
            class="app-footer m-0 tw-flex tw-justify-end tw-space-x-2 tw-sh"
            style="background-color: #e9ecef"
        >
            <ts-button
                @click.prevent="$router.push({ name: prevRoute.name })"
                class="btn-gray"
            >
                {{ $t("cancel") }}</ts-button
            >
            <ts-button
                color="primary"
                :waiting="waiting"
                :disabled="waiting_new"
                @click.prevent="onSave"
            >
                {{ $t("save") }}</ts-button
            >
        </div>
    </div>
</template>

<script>
import AppOptions from "@/config/AppOptions.vue";
import GeneralSetting from "./components/general-setting.vue";
import RepaymentItemPriority from "./components/repayment-item-priority.vue";
import OverduePenalty from "./components/overdue-penalty.vue";
import { mapActions, mapState } from "vuex";
import { Errors } from "form-backend-validation";

export default {
    name: "systemCreate",
    components: {
        GeneralSetting,
        RepaymentItemPriority,
        OverduePenalty
    },
    data() {
        return {
            loading: false,
            waiting: false,
            waiting_new: false,
            prevRoute: null,
            errors: new Errors(),
            model: {
                num_day_allow_reschedule: 0,
                is_approve_transaction_required: 0,
                pay_off_penalty_rate: 0,
                reschedule_penalty_rate: 0,
                decimal_digit: 0,
                idle_time_out: 1,
                repaymentItemPriority: [],
                overduePenalty: []
            }
        };
    },
    computed: {
        ...mapState("setting/system", [
            "generals",
            "repaymentItemPriorities",
            "overduePenalties"
        ])
    },
    created() {
        AppOptions.appContentFullHeight = true;
        AppOptions.appContentClass = "p-0";
    },
    methods: {
        ...mapActions("setting/system", [
            "getGeneral",
            "getRepaymentItemPriority",
            "getoverduePenalty"
        ]),
        ...mapActions("auth/user", ["getCurrentUser"]),
        async fetchResource() {
            this.loading = true;
            await this.getGeneral()
            .catch(error => {
                this.notice({ type: "error", text: error.message });
                this.loading = false;
            });
            await this.getRepaymentItemPriority();
            await this.getoverduePenalty();
            this.setData();
            this.loading = false;
        },
        setData() {
            if (this.generals) {
                this.model.num_day_allow_reschedule = this.generals.num_day_allow_reschedule;
                this.model.is_approve_transaction_required = this.generals.is_approve_transaction_required;
                this.model.pay_off_penalty_rate = this.generals.pay_off_penalty_rate;
                this.model.reschedule_penalty_rate = this.generals.reschedule_penalty_rate;
                this.model.decimal_digit = this.generals.decimal_digit;
                this.model.idle_time_out = this.generals.idle_time_out;
                this.model.repaymentItemPriority = this.repaymentItemPriorities
                this.model.overduePenalty = this.overduePenalties
            }
        },
        onSave() {
            this.errors = new Errors();
            this.waiting = true;
            this.$store
                .dispatch("setting/system/store", this.model)
                .then(response => {
                    this.notice({ type: "success", text: response.message });
                    this.fetchResource();
                    this.fetchUser();
                })
                .catch(error => {
                    this.errors = new Errors(error.errors);
                    this.notice({ type: "error", text: error.message });
                })
                .finally(() => {
                    this.waiting = false;
                });
        },
        async fetchUser() {
            await this.getCurrentUser()
                .then(response => {
                    localStorage.setItem(
                        "authUser",
                        JSON.stringify(response.data)
                    );
                    this.$store.commit("setAuthUser", response.data);
                })
                .catch(error => {
                    this.notice({ type: "error", text: error.message });
                });
        },
        notice(not) {
            this.$Notice[not.type]({
                title: "SYSTEM SETTING",
                desc: not.text
            });
        }
    },
    beforeRouteEnter(to, from, next) {
        next(vm => {
            vm.prevRoute = from
            vm.fetchResource();
        });
    },
    beforeRouteLeave(to, from, next) {
        AppOptions.appContentFullHeight = false;
        AppOptions.appContentClass = "";
        next();
    }
};
</script>
